<template>
  <li class="microcart-options-share">
    <a href="#" @click="toggleShare">
      <img src="/assets/icon/icon-share.svg">
      Warenkorb teilen
    </a>
    <div class="microcart-options-share-container" id="microcartContainer" :class="{ active: isActive }">
      <div class="microcart-options-share-container-close" @click="toggleShare" />
      <form name="shareCartForm">
        <label for="emailshare">Warenkorb per E-Mail versenden an:</label>
        <div class="share-email">
          <input name="email" v-model="email" class="input-text" placeholder="E-Mail Adresse">
          <a class="btn btn-primary" type="submit" @click="sendMail" :disabled="buttonsDisabled">
            <span>Senden</span>
          </a>
        </div>
        <div class="share-divider">
          <div class="text">
            oder
          </div>
          <div class="line" />
        </div>
        <div class="share-url">
          <label for="urlshare">Warenkorb-URL speichern/teilen:</label>
          <a class="btn btn-primary" v-if="!url" @click="generateUrl" :disabled="buttonsDisabled">URL anzeigen</a>
          <input type="text" v-if="url" v-model="url">
        </div>
      </form>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  data () {
    return {
      isActive: false,
      buttonsDisabled: false,
      url: '',
      email: ''
    }
  },
  computed: {
    ...mapGetters({
      getCartToken: 'cart/getCartToken',
      getUserToken: 'user/getToken'
    })
  },
  methods: {
    ...mapActions({
      notify: 'notification/spawnNotification'
    }),
    toggleShare () {
      this.isActive = !this.isActive
    },
    async generateUrl () {
      if (this.buttonsDisabled) return

      this.buttonsDisabled = true
      const { storeCode } = currentStoreView()

      const cartToken = this.getUserToken ? 'mine' : this.getCartToken;
      const response = await fetch('/rest/' + storeCode + '/V1/share-cart/' + cartToken + '/generate-url', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.getUserToken
        }
      })
      if (response.status === 200) {
        this.url = await response.json()
      }
      this.buttonsDisabled = false
    },
    async sendMail () {
      if (this.buttonsDisabled) return

      this.isActive = false
      this.buttonsDisabled = true
      const { storeCode } = currentStoreView()

      const cartToken = this.getUserToken ? 'mine' : this.getCartToken;
      const response = await fetch('/rest/' + storeCode + '/V1/share-cart/' + cartToken + '/send-email', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.getUserToken
        },
        body: JSON.stringify({
          email: this.email
        })
      })
      if (response.status === 200) {
        this.notify({
          type: 'success',
          message: this.$t('Cart was succesfully sent'),
          action1: { label: this.$t('OK') }
        })
      } else {
        this.notify({
          type: 'success',
          message: this.$t('Could not share your cart to the specified email.'),
          action1: { label: this.$t('OK') }
        })
      }
      this.buttonsDisabled = false
    }
  }
}
</script>
