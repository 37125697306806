<template>
  <li class="microcart-item" :class="{ 'relative': editMode }">
    <!-- div class="mx10 w-100 py10 mb15 edit-mode flex between-xs middle-xs" v-if="editMode">
      Edit mode
      <button class="brdr-none bg-cl-transparent" @click="closeEditMode">
        <span class="cl-accent">
          <i class="material-icons cl-accent mr5">
            close
          </i>
        </span>
      </button>
    </div -->

    <div class="microcart-item-image hidden-xs">
      <router-link
        :to="productLink"
        data-testid="productLink"
        @click.native="$store.commit('ui/setMicrocart', false)"
      >
        <product-image :image="image" />
      </router-link>
    </div>

    <div class="microcart-item-information">
      <router-link
        class="microcart-item-name"
        :to="productLink"
        data-testid="productLink"
        @click.native="$store.commit('ui/setMicrocart', false)"
      >
        {{ product.name | htmlDecode }}
      </router-link>

      <div class="microcart-item-sku" data-testid="productSku" v-if="false">
        {{ product.sku }}
      </div>

      <div class="options" v-if="isTotalsActive">
        <dl v-for="opt in product.totals.options" :key="opt.label">
          <dt class="opn">
            {{ opt.label }}:
          </dt>
          <dd class="opv" v-html="opt.value" />
        </dl>
      </div>
      <div class="options" v-else-if="!editMode && product.options">
        <dl v-for="opt in product.options" :key="opt.label">
          <dt class="opn">
            {{ opt.label }}:
          </dt>
          <dd class="opv" v-html="opt.value" />
        </dl>
      </div>
      <!-- div class="cl-error" v-if="hasProductErrors">
        {{ product.errors | formatProductMessages }}
      </div -->
      <div class="cl-success" v-if="false && hasProductInfo && !hasProductErrors">
        {{ product.info | formatProductMessages }}
      </div>
    </div>

    <div class="microcart-item-price hidden-xs">
      <div class="prices" v-if="!displayItemDiscounts || !isOnline">
        <!-- span class="price-special" v-if="product.special_price">
          {{ product.price_incl_tax | price }}
        </span>
        <span class="price-original" v-if="product.special_price">
          {{ product.original_price_incl_tax | price }}
        </span -->
        <span class="price-regular" data-testid="productPrice">
          {{ (product.original_price_incl_tax ? product.original_price_incl_tax : product.price_incl_tax) | price }}
        </span>
      </div>
      <div class="prices" v-else-if="isOnline && product.totals">
        <!-- span class="price-special" v-if="product.totals.discount_amount">
          {{ product.totals.row_total - product.totals.discount_amount + product.totals.tax_amount | price }}
        </span>
        <span class="price-original" v-if="product.totals.discount_amount">
          {{ product.totals.row_total_incl_tax | price }}
        </span -->
        <span class="price-regular">
          {{ product.totals.base_price_incl_tax | price }}
        </span>
      </div>
      <div class="prices" v-else>
        <span class="price-regular">
          {{ (product.totals) ? (product.totals.price_incl_tax | price) : '' }}
        </span>
      </div>
    </div>

    <product-quantity
      class="microcart-item-quantity"
      v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
      :value="productQty"
      :max-quantity="maxQuantity"
      :loading="isStockInfoLoading"
      :is-simple-or-configurable="isSimpleOrConfigurable"
      @input="updateProductQty"
      @error="handleQuantityError"
      :disabled="product.sku && product.sku.match(/^montage/) !== null"
    />

    <div class="microcart-item-summary">
      <div class="prices" v-if="!displayItemDiscounts || !isOnline">
        <span class="price-regular" data-testid="productPrice">
          {{ (product.original_price_incl_tax ? product.original_price_incl_tax : product.price_incl_tax) * product.qty | price }}
        </span>
      </div>
      <div class="prices" v-else-if="isOnline && product.totals">
        <span class="price-regular">
          {{ product.totals.row_total_incl_tax | price }}
        </span>
      </div>
      <div class="prices" v-else>
        <span class="price-regular">
          {{ (product.regular_price || product.price_incl_tax) * product.qty | price }}
        </span>
      </div>
    </div>

    <div class="microcart-item-remove" v-if="product.sku && !product.sku.match(/^montage/)">
      <remove-button @click="removeItem" />
    </div>
  </li>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import Product from '@vue-storefront/core/compatibility/components/blocks/Microcart/Product'

import ProductQuantity from 'theme/components/core/ProductQuantity.vue'
import ProductImage from 'theme/components/core/ProductImage'
// import ColorSelector from 'theme/components/core/ColorSelector.vue'
// import SizeSelector from 'theme/components/core/SizeSelector.vue'
import RemoveButton from './RemoveButton'
// import EditButton from './EditButton'
import { onlineHelper } from '@vue-storefront/core/helpers'
import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption'
import { getThumbnailForProduct, getProductConfiguration } from '@vue-storefront/core/modules/cart/helpers'
// import ButtonFull from 'theme/components/theme/ButtonFull'
import EditMode from './EditMode'

import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export default {
  data () {
    return {
      maxQuantity: 0,
      quantityError: false,
      isStockInfoLoading: false
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: {
    RemoveButton,
    ProductImage,
    // ColorSelector,
    // SizeSelector,
    // EditButton,
    // ButtonFull,
    ProductQuantity
  },
  mixins: [Product, ProductOption, EditMode],
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    hasProductInfo () {
      return this.product.info && Object.keys(this.product.info).length > 0
    },
    hasProductErrors () {
      return this.product.errors && Object.keys(this.product.errors).length > 0
    },
    isTotalsActive () {
      return this.isOnline && !this.editMode && this.product.totals && this.product.totals.options
    },
    isOnline () {
      return onlineHelper.isOnline
    },
    productsAreReconfigurable () {
      return config.cart.productsAreReconfigurable && this.product.type_id === 'configurable' && this.isOnline
    },
    displayItemDiscounts () {
      return config.cart.displayItemDiscounts
    },
    image () {
      return {
        loading: this.thumbnail,
        src: this.thumbnail
      }
    },
    thumbnail () {
      return getThumbnailForProduct(this.product)
    },
    configuration () {
      return getProductConfiguration(this.product)
    },
    productLink () {
      return formatProductLink(this.product, currentStoreView().storeCode)
    },
    editMode () {
      return this.getEditingProductId === this.product.id
    },
    productQty () {
      return this.editMode ? this.getEditingQty : this.product.qty
    },
    isSimpleOrConfigurable () {
      return ['simple', 'configurable'].includes(this.product.type_id)
    },
    isUpdateCartDisabled () {
      return this.quantityError ||
        this.isStockInfoLoading ||
        (this.isOnline && !this.maxQuantity && this.isSimpleOrConfigurable)
    }
  },
  methods: {
    updateProductVariant () {
      this.updateVariant()
      this.closeEditMode()
    },
    updateProductQty (qty) {
      if (this.editMode) {
        this.editModeSetQty(qty)
        return
      }

      this.updateQuantity(qty)
    },
    async removeFromCart () {
      await this.$store.dispatch('cart/removeItem', { product: this.product })
    },
    async updateQuantity (quantity) {
      await this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: quantity })
    },
    async getQuantity (product) {
      if (this.isStockInfoLoading) return // stock info is already loading
      this.isStockInfoLoading = true
      try {
        const validProduct = product || this.product
        const res = await this.$store.dispatch('stock/check', {
          product: validProduct,
          qty: this.productQty
        })
        return res.qty
      } finally {
        this.isStockInfoLoading = false
      }
    },
    handleQuantityError (error) {
      this.quantityError = error
    },
    async changeEditModeFilter (filter) {
      const editedProduct = this.getEditedProduct(filter)
      const maxQuantity = await this.getQuantity(editedProduct)
      if (!maxQuantity) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t(
            'The product is out of stock and cannot be added to the cart!'
          ),
          action1: { label: this.$t('OK') }
        })
      } else if (maxQuantity < this.productQty) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Only {maxQuantity} products of this type are available!', { maxQuantity }),
          action1: { label: this.$t('OK') }
        })
      } else {
        this.maxQuantity = maxQuantity
        this.editModeSetFilters(filter)
      }
    }
  },
  watch: {
    isOnline: {
      async handler (isOnline) {
        if (isOnline) {
          const maxQuantity = await this.getQuantity()
          this.maxQuantity = maxQuantity
        }
      }
    },
    isMicrocartOpen: {
      async handler (isOpen) {
        if (isOpen) {
          const maxQuantity = await this.getQuantity()
          this.maxQuantity = maxQuantity
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
  .blend {
    flex: 0 0 150px;
  }

  .image {
    mix-blend-mode: multiply;
    vertical-align: top;
  }

  .actions {
    margin: 0 -5px;
  }

  .prices {
    flex-direction: column;
    @media (max-width: 767px) {
      padding: 0;
      font-size: 12px;
    }
  }

  .price-original {
    text-decoration: line-through;
  }

  input {
    width: 30px;
  }

  .flex-nowrap {
    flex-wrap: nowrap;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .edit-mode {
    border-bottom: 1px solid color(white-smoke);
  }

  .filters {
    flex: 1 1 200px;
  }

  .update-button {
    font-size: 14px;
    min-width: 150px;
    width: 150px;
    padding: 10px;
  }
</style>
