<template>
  <div
    class="product-image"
    :class="{'product-image--height': basic, 'product-image--width': !basic}"
    v-on="$listeners"
  >
    <div class="image">
      <img
        v-if="!unlazy"
        v-lazy="{src: image.src, error: '/assets/placeholder.svg', loading: '/assets/placeholder.svg'}"
        :alt="alt"
        @load="imageLoaded('high', true)"
        @error="imageLoaded('high', false)"
        class="product-image__thumb lazy"
      >
      <img
        v-if="unlazy"
        :src="image.src"
        :alt="alt"
        @load="imageLoaded('high', true)"
        @error="imageLoaded('high', false)"
        class="product-image__thumb unlazy"
      >
    </div>
  </div>
</template>

<script>
import { onlineHelper } from '@vue-storefront/core/helpers'

export default {
  props: {
    calcRatio: {
      type: Boolean,
      default: true
    },
    image: {
      type: Object,
      default: () => ({
        src: '',
        loading: ''
      })
    },
    alt: {
      type: String,
      default: ''
    },
    unlazy: {
      type: Boolean
    }
  },
  data () {
    return {
      lowerQualityImage: false,
      lowerQualityImageError: false,
      highQualityImage: false,
      highQualityImageError: false,
      basic: true
    }
  },
  watch: {
    lowerQualityImage (state) {
      if (state) {
        this.basic = this.$refs.lQ.naturalWidth < this.$refs.lQ.naturalHeight;
      }
    }
  },
  computed: {
    showPlaceholder () {
      return !this.showLowerQuality && !this.showHighQuality
    },
    showLowerQuality () {
      return this.lowerQualityImage && !this.showHighQuality
    },
    showHighQuality () {
      return this.highQualityImage
    },
    imageRatio () {
      const { width, height } = this.$store.state.config.products.gallery
      return `${height / (width / 100)}%`
    },
    /* style () {
      return this.calcRatio ? {paddingBottom: this.imageRatio} : {}
    }, */
    isOnline (value) {
      return onlineHelper.isOnline
    }
  },
  methods: {
    imageLoaded (type, success = true) {
      this[`${type}QualityImage`] = success
      this[`${type}QualityImageError`] = !success
    }
  }
}
</script>
