<template>
  <section class="category-information" v-if="getBanners.length">
    <div
      v-for="(banner, index) in getBanners" :key="index"
    >
      <div v-html="banner.caption" />
    </div>
  </section>
</template>

<script>
import BannerSlider from 'src/modules/vsf-bannerslider/components/BannerSlider'

export default {
  mixins: [BannerSlider],
  async mounted () { // this is for SSR purposes to prefetch data
    this.$store.dispatch('bannerslider/list', { filterValues: [this.id], filterField: 'slider_id', includeFields: ['slider_id', 'name', 'image', 'image_alt', 'click_url', 'button_text', 'caption'] })
  }
}
</script>
